import writeXlsxFile from 'write-excel-file';

export default {
    data(){
        return {
			excel: {
				data: null,
				columns: null,
			},
        }
    },
    
	methods: {
		positions( leaderboard, type, stats ){
			const positions = Object.keys( leaderboard );
			
			return positions
				.map( username => {
					console.log(stats.users);
					const user = stats.users.find( u => u.username == username);
					
					return {
						user,
						...leaderboard[ username ][ type ],
					}
				})
				.filter( position => {
					// console.log(position);
					
					if (type == 'products'){
						return position.sum ? true : false
					}
					else if (type == 'tasks'){
						return position.qty ? true : false
					}
				})
				.sort((a, b) => {
					if (type == 'products'){
						return b.sum > a.sum ? 1 : -1
					}
					else if (type == 'tasks'){
						return b.qty > a.qty ? 1 : -1
					}
				})
		},
		
		generateLeaderboards( stats ){
			if ( !stats || !stats.leaderboard){
				return null;
			}
			
			let products = [];
			let tasks = [];

			for ( const [username, value] of Object.entries( stats.leaderboard ) ) {
				if ( value.totals.products.sum ) {
					products.push( {
						user: stats.users.find( u => u.username == username),
						sum: value.totals.products.sum,
						qty: value.totals.products.qty,
					});
				}
				if ( value.totals.tasks.qty ) {
					tasks.push( {
						user: stats.users.find( u => u.username == username),
						qty: value.totals.tasks.qty,	
					});
				}
			}
			
			products = products
				.filter( item => item.qty || item.sum )
				.sort((a, b) => b.sum > a.sum ? 1 : -1);
			tasks = tasks
				.filter( item => item.qty )
				.sort((a, b) => b.qty > a.qty ? 1 : -1);
			
			return {
				products,
				tasks,
			};
		},
		
        async onStatsExport( stats, departments, users ){
			console.log('stats', stats);
			
			const kudos = await this.$store.dispatch('listKudos', {
				from: stats.dateRange?.fromTimestamp,
				to: stats.dateRange?.toTimestamp,
			});
			
			
			
			const leaderboards = this.generateLeaderboards( stats );
			// console.log('leaderboards', leaderboards);
			
			const leaderboardProductsSchema = [
				{
					column: 'Plassering',
					type: Number,
					value: row => row.place,
					width: 20,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Navn',
					type: String,
					value: row => row.name,
					width: 40,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Salgssum',
					type: Number,
					format: '#,##0.00',
					value: row => row.sum,
					width: 30,
					height: 24,
					alignVertical: 'center',
				},
			];
			
			
			const leaderboardTasksSchema = [
				{
					column: 'Plassering',
					type: Number,
					value: row => row.place,
					width: 20,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Navn',
					type: String,
					value: row => row.name,
					width: 40,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Utførte oppgaver',
					type: Number,
					value: row => row.qty,
					width: 30,
					height: 24,
					alignVertical: 'center',
				},
			];
			
			const productsSchema = [
				{
					column: 'Produkter',
					type: String,
					value: row => row.name,
					width: 40,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Enheter solgt',
					type: Number,
					value: row => row.qty,
					width: 20,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Sum',
					type: Number,
					format: '#,##0.00',
					value: row => row.sum,
					width: 20,
					height: 24,
					alignVertical: 'center',
				},
			];
			
			const tasksSchema = [
				{
					column: 'Oppgaver',
					type: String,
					value: row => row.name,
					width: 40,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Oppgaver utført',
					type: Number,
					value: row => row.qty,
					width: 20,
					height: 24,
					alignVertical: 'center',
				},
			];
			
			const statsObjects = [];
			const statsSchemas = [];
			const statsSheets = [];
			
			
			
			
			
			
			if (leaderboards && leaderboards.products) {
				const leaderboardProducts = [];
				
				leaderboards.products.forEach( (item, place) => {
					leaderboardProducts.push({
						place: Number(place+1),
						name: item.user.given_name+' '+item.user.family_name,
						sum: Number(item.sum),
					});
				});
				
				statsObjects.push( leaderboardProducts );
				statsSchemas.push( leaderboardProductsSchema );
				statsSheets.push('Totalt - Topplister Produkter');
			}
			
			if (leaderboards && leaderboards.tasks) {
				const leaderboardTasks = [];
				
				leaderboards.tasks.forEach( (item, place) => {
					leaderboardTasks.push({
						place: Number(place+1),
						name: item.user.given_name+' '+item.user.family_name,
						qty: Number(item.qty),
					});
				});
				
				statsObjects.push( leaderboardTasks );
				statsSchemas.push( leaderboardTasksSchema );
				statsSheets.push('Totalt - Topplister Oppgaver');
			}
			
			
			
			
			
			
			
			
			
			
			const totalProducts = [];
			let totalProductsQty = 0;
			let totalProductsSum = 0;
			
			stats.departments.forEach( department => {
				totalProducts.push({
					name: department.name.replace(/,/g, ''),
					qty: Number(department.stats.totals.products.qty),
					sum: Number(department.stats.totals.products.sum),
				});
				
				totalProductsQty += Number(department.stats.totals.products.qty);
				totalProductsSum += Number(department.stats.totals.products.sum);
			});
			
			totalProducts.push({
				name: 'Totalt',
				qty: totalProductsQty,
				sum: totalProductsSum,
			});
			
			statsObjects.push( totalProducts );
			statsSchemas.push( productsSchema );
			statsSheets.push('Totalt - produkter');
			
			
			
			const totalTasks = [];
			let totalTasksQty = 0;
			
			stats.departments.forEach( department => {
				totalTasks.push({
					name: department.name.replace(/,/g, ''),
					qty: Number(department.stats.totals.tasks.qty),
				});
				
				totalTasksQty += Number(department.stats.totals.tasks.qty);
			});
			
			totalTasks.push({
				name: 'Totalt',
				qty: totalTasksQty,
			});
			
			statsObjects.push( totalTasks );
			statsSchemas.push( tasksSchema );
			statsSheets.push('Totalt - oppgaver');
			
			
			
			
			const kudosSchema = [
				{
					column: 'Til',
					type: String,
					value: row => row.to,
					width: 40,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Fra',
					type: String,
					value: row => row.from,
					width: 40,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Tidspunkt',
					type: String,
					value: row => row.createdAt,
					width: 40,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Avdeling',
					type: String,
					value: row => row.department,
					width: 40,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Hjerte',
					type: String,
					value: row => row.heart,
					width: 20,
					height: 24,
					alignVertical: 'center',
				},
				{
					column: 'Tommel Opp',
					type: String,
					value: row => row.thumbsUp,
					width: 20,
					height: 24,
					alignVertical: 'center',
				},
			];
			
			const totalKudos = [];
			
			// totalKudos.push({
			// 	to: 'Til',
			// 	from: 'Fra',
			// 	createdAt: 'Tidspunkt',
			// 	department: 'Avdeling',
			// 	heart: 'Hjerte',
			// 	thumbsUp: 'Tommel Opp',
			// });
			
			kudos.forEach( kudo => {
				totalKudos.push({
					// name: kudo.name.replace(/,/g, ''),
					to: this.usernameToName(kudo.username),
					from: this.usernameToName(kudo.from.username),
					createdAt: this.$moment( kudo.createdAt ).format('lll'),
					department: departments.find( d => d.departmentId == kudo.departmentId)?.name,
					heart: '' + (kudo.symbol == 'heart' ? 1 : 0),
					thumbsUp: '' + (kudo.symbol == 'thumbsUp' ? 1 : 0),
				});
				
				// totalTasksQty += Number(department.stats.totals.tasks.qty);
			});
			
			statsObjects.push( totalKudos );
			statsSchemas.push( kudosSchema );
			statsSheets.push('Totalt - kudos');
			
			
			
			
			// 1 loop all departments
				stats.departments.forEach( department => {
					// departments[1].stats.products
					// each department 
					
					const departmentNameFormated = department.name
						.replace(/([^a-z0-9\s]+)/gi, '')
						.trim()
						.slice(0,18); // max 31 chars, including " - produkter / - oppgaver"
					
					// if products 
						const deptartmentProducts = [];
						
						department.products.forEach( product => {
							let productRow = {};
							
							const productStat = department?.stats?.products[ product.productId ];
							
							if (productStat) {
								// console.log( productStat );
								
								deptartmentProducts.push({
									name: product.name.replace(/,/g, ''),
									qty: Number(productStat.qty),
									sum: Number(productStat.sum),
								});
							}
						});
						
						const productTotals = department?.stats?.totals?.products;
						
						deptartmentProducts.push({
							name: 'Totalt',
							qty: productTotals ? Number(productTotals.qty) : 0,
							sum: productTotals ? Number(productTotals.sum) : 0,
						});
						
						statsObjects.push( deptartmentProducts );
						statsSchemas.push( productsSchema );
						statsSheets.push(departmentNameFormated + ' - produkter');
						
					// if tasks 
						const deptartmentTasks = [];
						
						department.tasks.forEach( task => {
							let taskRow = {};
							
							const taskStat = department?.stats?.tasks[ task.taskId ];
							
							if (taskStat) {
								// console.log( taskStat );
								
								deptartmentTasks.push({
									name: task.name.replace(/,/g, ''),
									qty: Number(taskStat.qty),
								});
							}
						});
						
						const taskTotals = department?.stats?.totals?.tasks;
						
						deptartmentTasks.push({
							name: 'Totalt',
							qty: taskTotals ? Number(taskTotals.qty) : 0,
						});
						
						statsObjects.push( deptartmentTasks );
						statsSchemas.push( tasksSchema );
						statsSheets.push(departmentNameFormated + ' - oppgaver');
					
						
					// leaderboards
						const departmentNameFormatedNew = department.name
							.replace(/([^a-z0-9\s]+)/gi, '')
							.trim()
							.slice(0, (31-23) ); // max 31 chars, including " - produkter / - oppgaver"
						
							
						if (department.stats?.leaderboard) {
							const leaderboard = this.positions( department.stats.leaderboard, 'products', stats );
							
							if (leaderboard && leaderboard.length) {
								const leaderboardProducts = [];
								
								leaderboard.forEach( (item, place) => {
									leaderboardProducts.push({
										place: Number(place+1),
										name: item.user.given_name+' '+item.user.family_name,
										sum: Number(item.sum),
									});
								});
								
								statsObjects.push( leaderboardProducts );
								statsSchemas.push( leaderboardProductsSchema );
								statsSheets.push(departmentNameFormatedNew + ' - Topplister Produkter');
							}
						}
							
						if (department.stats?.leaderboard) {
							const leaderboard = this.positions( department.stats.leaderboard, 'tasks', stats );
							
							if (leaderboard && leaderboard.length) {
								const leaderboardTasks = [];
								
								leaderboard.forEach( (item, place) => {
									leaderboardTasks.push({
										place: Number(place+1),
										name: item.user.given_name+' '+item.user.family_name,
										qty: Number(item.qty),
									});
								});
								
								statsObjects.push( leaderboardTasks );
								statsSchemas.push( leaderboardTasksSchema );
								statsSheets.push(departmentNameFormatedNew + ' - Topplister Oppgaver');
							}
						}
				});
				
            let fileTitle = `Upsell - ${this.$moment( stats.dateRange.fromTimestamp ).format('lll')} to ${this.$moment( stats.dateRange.toTimestamp ).format('lll')}`;
			
			return await writeXlsxFile( statsObjects, {
				schema: statsSchemas,
				sheets: statsSheets,
				fontFamily: 'Arial',
				fontSize: 10,
				headerStyle: {
					fontWeight: 'bold',
					backgroundColor: '#f0f0eb',
					color: '#333333',
					stickyRowsCount: 1,
					height: 24,
					alignVertical: 'center',
				},
				fileName: fileTitle+'.xlsx', //'fileTitle' + '.xlsx', //'Upsell Export.xlsx',
			});
        },
        
		async exportExcelFile(headers, items, fileTitle) {
			console.log(headers, items, fileTitle);
			
			const columnSetup = {
				height: 24,
				alignVertical: 'center',
			};
			
			const columnSetupHeader = {
				fontWeight: 'bold',
				backgroundColor: '#f0f0eb',
				color: '#333333',
				stickyRowsCount: 1,
			};
			
			this.excel.data = [];
			let headerArray = [];
			
			for (const [key, value] of Object.entries( headers ) ) {
				headerArray.push({ // Column
					value,
					...columnSetup,
					...columnSetupHeader,
				});
			}
			
			this.excel.data.push( headerArray );
			
			const columnTransactions = {
				// height: 24,
				// alignVertical: 'bottom',
			};
			
			items.forEach( transaction => {
				let rowArray = [];
				
				for (const [key, value] of Object.entries( headers ) ) {
					rowArray.push({
						// value: transaction[ key ],
						value: transaction[ key ],
						...columnSetup,
						...columnTransactions,
					});
				}
				
				this.excel.data.push( rowArray );
			});
			
			this.excel.columns = [
				// { width: 15 }, // transactionId
				{ width: 30 }, // department
				{ width: 40 }, // user
				{ width: 22 }, // date
				{ width: 10 }, // transactionType
				{ width: 30 }, // name
				{ width: 8 }, // value
				{ width: 8 }, // qty
				{ width: 8 }, // sum
				{ width: 60 }, // comment
			];
			
			// will use file download to blob in browser.
			return await writeXlsxFile( this.excel.data, 
				{
					columns: this.excel.columns,
					...this.excel.options,
					fileName: fileTitle + '.xlsx', //'Upsell Export.xlsx',
				}
			);
		},
		
	},
}
