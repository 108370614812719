<template>
	<div class="w-full h-full flex flex-col overflow-auto ">
	   
		<div class="w-full h-full flex flex-col">
			<Header :classes="'items-end'">
				<div>
					<h1>Oversikt</h1>
				</div>
				
				<Tabs 
					class="flex-none justify-center md:justify-start"
					:tabs="tabs" 
					:active="activeSection" 
					@click="activeSection = $event.alias"
				/>
			</Header>
			
			<OverviewNavigation 
				v-if="activeSection == 'historic'"
				@loading="loading = $event"
				@fetchedStatsHistoric="statsHistoric = $event"
				class="flex-none px-6 py-4"
			/>
			
			<div class="w-full h-full flex flex-col overflow-hidden">
				<div class="flex-grow overflow-auto p-6 md:p-12">
					<Overview 
						v-if="activeSection == 'current'"
						class="max-w-screen-lg mx-auto"
						:stats="stats" 
						:loading="loading"
						:userStats="true"
					/>
					<Overview
						v-else-if="activeSection == 'historic'"
						class="max-w-screen-lg mx-auto"
						:stats="statsHistoric" 
						:loading="loading"
						:userStats="true"
						:useDepartmentTargets="false"
					/>
				</div>
			</div>
			
			<div 
				v-if="activeSection == 'historic' && statsHistoric?.account && statsHistoric.departments && statsHistoric.departments.length"
				class="flex-none flex p-6 space-x-4 mx-auto">
				
				<button 
					type="submit"
					@click="onStatsExport( statsHistoric, departments, users )"
					class="button submit">
					
					Last ned Excel-regneark
				</button>
			</div>
		</div>
		<TransactionNotice />
	</div>
</template>

<script>
	import ExportStatsMixins from '@/mixins/ExportStatsMixins';

	export default {
		mixins: [
			ExportStatsMixins,
		],
		
		components: {
			'Overview': () => import('@/components/stats/Overview.vue'),
			'OverviewNavigation': () => import('@/components/stats/OverviewNavigation.vue'),
			'TransactionNotice': () => import('@/components/TransactionNotice.vue'),
		},
		
		computed: {
			stats(){
				return this.$store.getters.getStats;
			},
			
			departments(){
				return this.$store.getters.getDepartments;
			},
			
			users(){
				return this.$store.getters.getUsers;
			},
		},
		
		data(){
			return {
				loading: null,
				pollingIntervalFunction: null,
				overviewMode: 'current',
				statsHistoric: null,
				
				tabs: [
					{
						name: 'Gjeldende periode',
						alias: 'current',
					},
					{
						name: 'Historisk',
						alias: 'historic',
					},
				],
				activeSection: 'current',
				exportFormat: 'excel',
			}
		},
		
		async mounted(){
			this.pollingIntervalFunction = setInterval(  () => {
				this.$store.dispatch('listStats');
			}, 1000 * 60);
			
			this.loading = 'fetching';
			await this.$store.dispatch('listStats');
			
			this.loading = null;
			
			if ( !this.$store.getters.getUsers ) {
				this.loading = 'fetching';
				await this.$store.dispatch('listUsers');
				this.loading = null;
			}
		},
		
		beforeDestroy() {
			if (this.pollingIntervalFunction) {
				clearInterval(this.pollingIntervalFunction);
			}
		},
	}
	</script>